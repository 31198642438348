var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap doc_req_pop",
      staticStyle: { width: "950px", height: "600px" },
    },
    [
      _c("form", { attrs: { id: "frm" } }, [
        _c(
          "button",
          {
            staticClass: "layer_close",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_c("span", [_vm._v("close")])]
        ),
        _c(
          "div",
          { staticClass: "popup_cont" },
          [
            _c("h1", { staticClass: "page_title" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.001")) + " "),
            ]),
            _c("div", { staticClass: "content_box" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.002")) + " "),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(0),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.003")) + " "),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.parentInfo.bkgNo))]),
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.004")) + " "),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.dangerDocInfo.blNo))]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.005")) + " "),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.dangerDocInfo.polPlcNm))]),
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.006")) + " "),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.dangerDocInfo.podPlcNm))]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.007")) + " "),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.dangerDocInfo.vslNm))]),
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.008")) + " "),
                    ]),
                    _c("td", [_vm._v(_vm._s(_vm.dangerDocInfo.voyNo))]),
                  ]),
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.009")) + " "),
                    ]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.dangerDocInfo.actShprCstEnm)),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "content_box mt10" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.010")) + " "),
              ]),
              _c("table", { staticClass: "tbl_col mt10" }, [
                _vm._m(1),
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.011")) + " "),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.012")) + " "),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.013")) + " "),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.014")) + " "),
                    ]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.dangerDocInfo.shprCstNm))]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.reqPicNo,
                              expression: "formData.reqPicNo",
                            },
                          ],
                          staticClass: "inp_space50",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.formData,
                                  "reqPicNo",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.changeReqPicNm($event)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "all" } }, [
                            _vm._v("직접입력"),
                          ]),
                          _vm._l(_vm.managerList, function (option) {
                            return _c(
                              "option",
                              {
                                key: option.picNo,
                                domProps: {
                                  value:
                                    option.picNo +
                                    "/" +
                                    option.picNm +
                                    "/" +
                                    option.telPlcNo +
                                    "/" +
                                    option.telOfcNo +
                                    "/" +
                                    option.telNo +
                                    "/" +
                                    option.emlAddr,
                                },
                              },
                              [_vm._v(" " + _vm._s(option.picNm) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.reqPicNm,
                            expression: "formData.reqPicNm",
                          },
                        ],
                        staticClass: "inp_space50",
                        attrs: {
                          id: "reqPicNm",
                          type: "text",
                          readonly: _vm.readOnly === true,
                        },
                        domProps: { value: _vm.formData.reqPicNm },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formData,
                                "reqPicNm",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.checkValidationFrm(
                                _vm.formData,
                                "reqPicNm"
                              )
                            },
                          ],
                        },
                      }),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "reqPicTelNo",
                            "is-phone": true,
                            readonly: _vm.readOnly === true,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkValidationFrm(
                                _vm.formData,
                                "reqPicTelNo"
                              )
                            },
                          },
                          model: {
                            value: _vm.formData.reqPicTelNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "reqPicTelNo", $$v)
                            },
                            expression: "formData.reqPicTelNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.reqPicEmlAddr,
                            expression: "formData.reqPicEmlAddr",
                          },
                        ],
                        staticClass: "inp_space80",
                        attrs: {
                          id: "reqPicEmlAddr",
                          type: "text",
                          placeholder: "E-mail주소",
                          readonly: _vm.readOnly === true,
                        },
                        domProps: { value: _vm.formData.reqPicEmlAddr },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formData,
                                "reqPicEmlAddr",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.checkValidationFrm(
                                _vm.formData,
                                "reqPicEmlAddr"
                              )
                            },
                          ],
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("table", { staticClass: "tbl_col mt10" }, [
                _vm._m(2),
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.015")) + " "),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.016")) + " "),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.017")) + " "),
                    ]),
                    _c("th", { attrs: { scope: "col" } }, [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addContainerDG()
                            },
                          },
                        },
                        [_c("span", [_vm._v("plus")])]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.containerInfo, function (vo, idx) {
                    return _c("tr", { key: "tr_container_" + idx }, [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.cntrNo,
                              expression: "vo.cntrNo",
                            },
                          ],
                          attrs: {
                            id: "cntrNo_" + idx,
                            type: "text",
                            maxlength: "11",
                          },
                          domProps: { value: vo.cntrNo },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "cntrNo", $event.target.value)
                              },
                              function ($event) {
                                return _vm.checkValidationContainerInfo(
                                  "cntrNo",
                                  idx
                                )
                              },
                            ],
                            blur: function ($event) {
                              return _vm.containerChk(idx)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.scgAppNo,
                                expression: "vo.scgAppNo",
                              },
                            ],
                            attrs: { name: "scgAppNo" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "scgAppNo",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.dgCrtfNoList, function (option) {
                            return _c(
                              "option",
                              {
                                key: option.scgAppNo,
                                domProps: { value: option.scgAppNo },
                              },
                              [_vm._v(" " + _vm._s(option.scgAppNo) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.dgCrtfNo,
                              expression: "vo.dgCrtfNo",
                            },
                          ],
                          attrs: {
                            id: "dgCrtfNo_" + idx,
                            type: "text",
                            maxlength: "20",
                          },
                          domProps: { value: vo.dgCrtfNo },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "dgCrtfNo", $event.target.value)
                              },
                              function ($event) {
                                return _vm.checkValidationContainerInfo(
                                  "dgCrtfNo",
                                  idx
                                )
                              },
                            ],
                          },
                        }),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon minus",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeContainerDG(idx)
                              },
                            },
                          },
                          [_c("span", [_vm._v("minus")])]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _c("table", { staticClass: "tbl_col mt10" }, [
                _vm._m(3),
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.018")) + " "),
                    ]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.reqRsnCont,
                            expression: "formData.reqRsnCont",
                          },
                        ],
                        attrs: { id: "reqRsnCont", type: "text" },
                        domProps: { value: _vm.formData.reqRsnCont },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.formData,
                                "reqRsnCont",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.checkValidationFrm(
                                _vm.formData,
                                "reqRsnCont"
                              )
                            },
                          ],
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(4),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL100_P1.019")) + " "),
                    ]),
                    _c(
                      "td",
                      { staticClass: "text_left", attrs: { colspan: "8" } },
                      [
                        _c(
                          "div",
                          { staticClass: "filebox", attrs: { id: "file_att" } },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.uploadParam.length != 0
                                    ? "button sm blue"
                                    : "button sm gray file",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.openPopup()
                                  },
                                },
                              },
                              [_vm._v("파일첨부/확인")]
                            ),
                          ]
                        ),
                        _c("p", { staticClass: "txt_desc" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ADMN040G020.038"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "text_center mt10" }, [
              _c(
                "a",
                {
                  staticClass: "button blue lg ml_auto mr5",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.dangerDocInsert()
                    },
                  },
                },
                [_vm._v("요청")]
              ),
              _c(
                "a",
                {
                  staticClass: "button gray lg",
                  attrs: { href: "#none" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("닫기")]
              ),
            ]),
            _c(
              "win-layer-pop",
              { staticClass: "popup_dim" },
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.uploadParam },
                      on: {
                        close: _vm.closePopup,
                        "set-dext-file-info": _vm.arrangeUploadParam,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "27%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "auto" } }),
      _c("col", { staticStyle: { width: "31%" } }),
      _c("col", { staticStyle: { width: "31%" } }),
      _c("col", { staticStyle: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { staticStyle: { width: "auto" } })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }