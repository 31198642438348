<template>
  <div class="popup_wrap doc_req_pop" style="width:950px; height:600px;">
    <form id="frm">
      <button class="layer_close" @click="$emit('close')">
        <span>close</span>
      </button>
      <div class="popup_cont">
        <h1 class="page_title">
          {{ $t('msg.CSBL100_P1.001') }}
        </h1><!-- 위험물반입신고 요청서 -->
        <div class="content_box">
          <!-- content_box -->
          <h2 class="content_title">
            {{ $t('msg.CSBL100_P1.002') }}
          </h2><!-- 스케쥴 -->
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:15%">
              <col style="width:35%">
              <col style="width:15%">
              <col style="width:35%">
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  {{ $t('msg.CSBL100_P1.003') }}
                </th><!-- Booking No. -->
                <td>{{ parentInfo.bkgNo }}</td>
                <th scope="row">
                  {{ $t('msg.CSBL100_P1.004') }}
                </th><!-- B/L No. -->
                <td>{{ dangerDocInfo.blNo }}</td>
              </tr>
              <tr>
                <th scope="row">
                  {{ $t('msg.CSBL100_P1.005') }}
                </th><!-- 출발 -->
                <td>{{ dangerDocInfo.polPlcNm }}</td>
                <th scope="row">
                  {{ $t('msg.CSBL100_P1.006') }}
                </th><!-- 도착 -->
                <td>{{ dangerDocInfo.podPlcNm }}</td>
              </tr>
              <tr>
                <th scope="row">
                  {{ $t('msg.CSBL100_P1.007') }}
                </th><!-- 선명 -->
                <td>{{ dangerDocInfo.vslNm }}</td>
                <th scope="row">
                  {{ $t('msg.CSBL100_P1.008') }}
                </th><!-- 항차 -->
                <td>{{ dangerDocInfo.voyNo }}</td>
              </tr>
              <tr>
                <th scope="row">
                  {{ $t('msg.CSBL100_P1.009') }}
                </th><!-- Shipper -->
                <td colspan="3">
                  <span>{{ dangerDocInfo.actShprCstEnm }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="content_box mt10">
          <h2 class="content_title">
            {{ $t('msg.CSBL100_P1.010') }}
          </h2><!-- 요청자 연락처 -->
          <table class="tbl_col mt10">
            <colgroup>
              <col style="width:auto">
              <col style="width:25%">
              <col style="width:20%">
              <col style="width:27%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  {{ $t('msg.CSBL100_P1.011') }}
                </th><!-- 회사명 -->
                <th scope="col">
                  {{ $t('msg.CSBL100_P1.012') }}
                </th><!-- 담당자 명 -->
                <th scope="col">
                  {{ $t('msg.CSBL100_P1.013') }}
                </th><!-- 연락처 -->
                <th scope="col">
                  {{ $t('msg.CSBL100_P1.014') }}
                </th><!-- email -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ dangerDocInfo.shprCstNm }}</td>
                <td>
                  <select
                    v-model="formData.reqPicNo"
                    class="inp_space50"
                    @change="changeReqPicNm($event)"
                  >
                    <option value="all">직접입력</option>
                    <option
                      v-for="option in managerList"
                      :key="option.picNo"
                      :value="option.picNo+'/'+option.picNm+'/'+option.telPlcNo+'/'+option.telOfcNo+'/'+option.telNo+'/'+option.emlAddr"
                    >
                      {{ option.picNm }}
                    </option>
                  </select>

                  <input
                    v-model="formData.reqPicNm"
                    class="inp_space50"
                    id="reqPicNm"
                    type="text"
                    :readonly="readOnly === true"
                    @input="checkValidationFrm(formData, 'reqPicNm')"
                  >
                </td>
                <td>
                  <e-input-number
                    v-model="formData.reqPicTelNo"
                    :id="'reqPicTelNo'"
                    :is-phone="true"
                    :readonly="readOnly === true"
                    @input="checkValidationFrm(formData, 'reqPicTelNo')"
                  >
                  </e-input-number>
                </td>
                <td>
                  <input
                    v-model="formData.reqPicEmlAddr"
                    id="reqPicEmlAddr"
                    type="text"
                    class="inp_space80"
                    placeholder="E-mail주소"
                    :readonly="readOnly === true"
                    @input="checkValidationFrm(formData, 'reqPicEmlAddr')"
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <table class="tbl_col mt10">
            <colgroup>
              <col style="width:auto">
              <col style="width:31%">
              <col style="width:31%">
              <col style="width:5%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  {{ $t('msg.CSBL100_P1.015') }}
                </th><!-- Container -->
                <th scope="col">
                  {{ $t('msg.CSBL100_P1.016') }}
                </th><!-- Reference No. -->
                <th scope="col">
                  {{ $t('msg.CSBL100_P1.017') }}
                </th><!-- DG Certi No. -->
                <th scope="col">
                  <button type="button" class="tbl_icon plus" @click.prevent="addContainerDG()">
                    <span>plus</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(vo, idx) in containerInfo" :key="'tr_container_' + idx">
                <td>
                  <input
                    :id="'cntrNo_' + idx"
                    v-model="vo.cntrNo"
                    type="text"
                    maxlength="11"
                    @input="checkValidationContainerInfo('cntrNo', idx)"
                    @blur="containerChk(idx)"
                  >
                </td>
                <td>
                  <select name="scgAppNo" v-model="vo.scgAppNo">
                    <option
                      v-for="option in dgCrtfNoList"
                      :key="option.scgAppNo"
                      :value="option.scgAppNo"
                    >
                      {{ option.scgAppNo }}
                    </option>
                  </select>
                </td>
                <td>
                  <input
                    :id="'dgCrtfNo_' + idx"
                    v-model="vo.dgCrtfNo"
                    @input="checkValidationContainerInfo('dgCrtfNo', idx)"
                    type="text"
                    maxlength="20"
                  >
                </td>
                <td>
                  <button class="tbl_icon minus" @click.prevent="removeContainerDG(idx)">
                    <span>minus</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="tbl_col mt10">
            <colgroup>
              <col style="width:auto">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  {{ $t('msg.CSBL100_P1.018') }}
                </th><!-- 사유 및 요청사항 -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    v-model="formData.reqRsnCont"
                    id="reqRsnCont"
                    type="text"
                    @input="checkValidationFrm(formData, 'reqRsnCont')"
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:20%">
              <col style="width:auto">
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  {{ $t('msg.CSBL100_P1.019') }}
                </th><!-- DG Certi No. 첨부 -->
                <td colspan="8" class="text_left">
                  <div class="filebox" id="file_att">
                    <a :class="uploadParam.length != 0 ? 'button sm blue' : 'button sm gray file'" href="#" @click.prevent="openPopup()">파일첨부/확인</a>
                    <!-- <label for="file">{{ $t('msg.ADMN040G020.004') }}</label>
                  <input id="file" type="file" @click.prevent="openPopup()" /> -->
                  </div>
                  <p class="txt_desc">{{ $t('msg.ADMN040G020.038') }}</p><!-- 파일명에 특수문자 또는 공백이 포함되어 있거나 파일의 크기가 200KB를 초과할 경우 첨부가 이뤄지지 않습니다. -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text_center mt10">
          <a
            href="#none"
            class="button blue lg ml_auto mr5"
            @click.prevent="dangerDocInsert()"
          >요청</a>
          <a
            href="#none"
            class="button gray lg"
            @click="$emit('close')"
          >닫기</a>
        </div>
        <win-layer-pop class="popup_dim">
          <component
            v-if="customComponent"
            :is="customComponent"
            @close="closePopup"
            :parent-info="uploadParam"
            @set-dext-file-info="arrangeUploadParam"
          />
        </win-layer-pop>
      </div><!-- popup_cont -->
    </form>
  </div><!-- popup_wrap // -->
</template>

<script>
import docs from '@/api/rest/trans/docs'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import DocUploadPop from '@/pages/trans/popup/DocUploadPop'
import EInputNumber from '@/components/common/EInputNumber'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'DangerDocPop',
  components: {
    EInputNumber,
    DocUploadPop,
    WinLayerPop,
    rootComputed
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgNo: ''
        }
      }
    }
  },
  data () {
    return {
      formData: {
        bkgNo: '',
        kind: '2',
        vslCd: '',
        voyNo: '',
        polCtrCd: '',
        polPortCd: '',
        podPortCd: '',
        shprCstNo: '',
        shprCstNm: '',
        reqRsnCont: '',
        blNo: '',
        reqPicNo: 'all',
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        reqPicNmS: '',
        containerList: [],
        //파일 정보
        uploadFileInfos: []
      },
      containerInfo: [
        {
          cntrSeq: '1',
          cntrNo: '',
          scgAppNo: '',
          dgCrtfNo: ''
        }
      ],
      dangerDocInfo: {
        vslCd: '',
        voyNo: '',
        polCtrCd: '',
        polPortCd: '',
        podCtrCd: '',
        podPortCd: '',
        shprCstNo: '',
        shprCstNm: '',
        reqRsnCont: '',
        blNo: ''
      },
      managerList: [],
      dgCrtfNoList: [],
      readOnly: false,
      //업로드 관련 파라미터(파일 첨부 시, 값이 담기고 CUD 시 parameter에 옮김)
      uploadParam: [],
      popupParams: {},
      customComponent: null
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.formData = this.parentInfo
    this.init()
    this.getDangerDocInfo()
  },
  methods: {
    async init () {
      // 로그인한 정보를 이용하여 프로필 정보 가져오기
      if (this.auth.userId) {
        const data = this.selectedProfile
        this.formData.entPicNo = data.picNo
        this.formData.reqPicNm = data.picNm
        this.formData.reqPicEmlAddr = data.emlAddr

        let usrTelNo = data.telPlcNo || ''

        if (this.$ekmtcCommon.isNotEmpty(data.telOfcNo)) {
          usrTelNo += '-' + data.telOfcNo
        }

        if (this.$ekmtcCommon.isNotEmpty(data.telNo)) {
          usrTelNo += '-' + data.telNo
        }

        this.formData.reqPicTelNo = usrTelNo
      }
    },
    //위험물 포함된 bkgNo인지 체크 및 초기화면 정보 가져오기
    async getDangerDocInfo () {
      await docs.getDangerDocInfo(this.parentInfo)
        .then(response => {
          if (response.data.rtnStr === 'Y') {
            if (response.status === 200) {
              //담당자명 세팅
              this.managerList = [...this.managerList, ...response.data.managerList]
              //Schedule 정보 세팅
              this.dangerDocInfo = response.data.dangerDocInfo
              this.dgCrtfNoList = response.data.dgCrtfNoList

              if (this.dgCrtfNoList !== undefined && this.dgCrtfNoList.length > 0) {
                this.containerInfo[0].scgAppNo = this.dgCrtfNoList[0].scgAppNo
              }
            }
          } else {
            this.$ekmtcCommon.alertDefault(response.data.msgStr)
            this.$emit('close')
            return false
          }
        })
        .catch(err => {
          console.log(err)
          this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
          this.$emit('close')
        })
    },
    //컨테이너 No. 체크
    containerChk (idx) {
      const alertOpt = {
        alertType: 'normal',
        customCloseBtnText: this.$t('msg.NEWB010P040.001'), // 닫기
        type: 'info'
      }

      const cntrNo = this.containerInfo[idx].cntrNo.toUpperCase()

      if (cntrNo.length > 10) {
        // 220625 컨테이너 벨리데이션 체크 막아달라는 요청
        // docs.containerChk(cntrNo)
        //   .then(response => {
        //     if (response.data === 'N') {
        //       this.$ekmtcCommon.alertDefault(this.$t('art.CMATK363'))
        //       this.containerInfo[idx].cntrNo = ''
        //       return false
        //     }
        // })
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK363'))
        this.containerInfo[idx].cntrNo = ''
        return false
      }
    },
    //행 추가
    addContainerDG () {
      const containerList = this.containerInfo
      const containerLen = containerList.length
      const cntrSeq = containerLen === 0 ? 1 : Number(containerList[containerLen - 1].cntrSeq) + 1
      containerList.push(
        {
            cntrSeq: cntrSeq,
            cntrNo: '',
            scgAppNo: this.dgCrtfNoList[0].scgAppNo,
            dgCrtfNo: ''
          }
      )
    },
    //행 삭제
    removeContainerDG (idx) {
      const containerList = this.containerInfo
      containerList.splice(idx, 1)

      const containerLen = containerList.length

      if (containerLen === 0) {
        this.addContainerDG()
      }
    },
    //담당자 이름 선택시 이메일, 전화번호 자동 매핑
    changeReqPicNm (e) {
      const TH = this
      const val = e.target.value
      let arrTempData = val.replace('undefined', '').split('/')

      //초기화
      this.readOnly = false

      TH.formData.reqPicNm = ''
      TH.formData.reqPicTelNo = ''
      TH.formData.reqPicEmlAddr = ''

      if (arrTempData[0] === 'all') {
        this.readOnly = false
      } else {
        TH.formData.reqPicNm = arrTempData[1]
        TH.formData.reqPicTelNo = arrTempData[2] + arrTempData[3] + arrTempData[4]
        TH.formData.reqPicEmlAddr = arrTempData[5]

        TH.readOnly = true
      }

      this.checkValidationFrm(TH.formData, 'reqPicNm')
      this.checkValidationFrm(TH.formData, 'reqPicTelNo')
      this.checkValidationFrm(TH.formData, 'reqPicEmlAddr')
    },
    checkValidationFrm (frm, id) {
      const pop = document.querySelector('.doc_req_pop')
      const selector = pop.querySelector('#' + id)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      let isOk = true

      if (id === 'reqPicNm') {
        if (frm.reqPicNm === '' || frm.reqPicNm === undefined) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P130.002'))
          isOk = false
        }
      } else if (id === 'reqPicTelNo') {
        if (frm.reqPicTelNo === '' || frm.reqPicTelNo === undefined) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P130.003'))
          isOk = false
        } else {
          if (!this.$ekmtcCommon.checkTextByte(frm.reqPicTelNo, 100)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P130.005'))
            isOk = false
          }
        }
      } else if (id === 'reqPicEmlAddr') {
        if (this.$ekmtcCommon.isEmpty(frm.reqPicEmlAddr)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P130.004'))
          isOk = false
        } else if (!this.$ekmtcCommon.checkEmail(frm.reqPicEmlAddr)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK196')) // E-Mail 형식 올바르지 않습니다.
          isOk = false
        } else {
          if (!this.$ekmtcCommon.checkTextByte(frm.reqPicEmlAddr, 100)) {
            //이메일, 전화번호 Byte 체크(공통 호출)
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P130.005'))
            isOk = false
          }
        }
      } else if (id === 'reqRsnCont') {
        if (!this.$ekmtcCommon.checkTextByte(frm.reqRsnCont, 2000)) {
          //내용 Byte 체크(공통 호출)
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P130.005'))
          isOk = false
        }
      }

      return isOk
    },
    checkValidationContainerInfo (id, idx) {
      let isOk = true
      const containerInfo = this.containerInfo[idx]
      const pop = document.querySelector('.doc_req_pop')
      const selector = pop.querySelector('#' + id + '_' + idx)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (this.$ekmtcCommon.isEmpty(selector.value)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      if (id === 'cntrNo') {
        this.containerInfo.forEach((c, i) => {
          if (i !== idx) {
            if (c.cntrNo === containerInfo.cntrNo) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.NEWB010P130.008'))
              isOk = false
            }
          }
        })
      }

      return isOk
    },
    //frm validation 확인
    checkValidationAll () {
      let isAllOk = true

      // this.formData = [...this.formData, ...this.dangerDocInfo]
      const containerInfo = this.containerInfo
      const frm = this.formData

      const arrChkEl = ['reqPicNm', 'reqPicTelNo', 'reqPicEmlAddr', 'reqRsnCont']

      arrChkEl.forEach(el => {
        if (!this.checkValidationFrm(frm, el)) {
          isAllOk = false
        }
      })

      const arrChkCntr = ['cntrNo', 'dgCrtfNo']
      arrChkCntr.forEach(el => {
        containerInfo.forEach((c, idx) => {
          if (!this.checkValidationContainerInfo(el, idx)) {
          isAllOk = false
          }
        })
      })

      //파일 밸리데이션
      if (this.formData.uploadFileInfos.length === 0) {
        const attachSelector = document.querySelector('#file_att')
        this.$ekmtcCommon.showErrorTooltip(attachSelector, this.$t('msg.CSBK100.147'))
        isAllOk = false
      }

      return isAllOk
    },
    //위험물 요청 insert
    async dangerDocInsert () {
      let isOk = true

      //파일 관련 작업
      this.formData.uploadFileInfos = this.uploadParam.map((item) => {
        if (item.new !== true) {
          item.fileId = item.asFileId
        }
        return item
      })

      if (!this.checkValidationAll()) {
        return
      }

      if (isOk) {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.248'), useConfirmBtn: true })) {
          this.formData.containerList = this.containerInfo
          this.formData.vslCd = this.dangerDocInfo.vslCd
          this.formData.voyNo = this.dangerDocInfo.voyNo
          this.formData.polCtrCd = this.dangerDocInfo.polCtrCd
          this.formData.polPortCd = this.dangerDocInfo.polPortCd
          this.formData.podCtrCd = this.dangerDocInfo.podCtrCd
          this.formData.podPortCd = this.dangerDocInfo.podPortCd
          this.formData.shprCstNo = this.dangerDocInfo.shprCstNo
          this.formData.shprCstNm = this.dangerDocInfo.shprCstNm
          this.formData.reqRsnCont = this.dangerDocInfo.reqRsnCont
          this.formData.blNo = this.dangerDocInfo.blNo
          this.formData.reqCatCd = '05'

          docs.reqDocSubmit(this.formData)
            .then((response) => {
              console.log(response)
              if (response.headers.respcode === 'C0000') {
                const TH = this
                TH.$ekmtcCommon.alertCallback(TH.$t('msg.CSBK100.236'), function () {
                  TH.$emit('close')
                })
            } else {
              TH.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.237')) // 요청 접수에 실패하였습니다.
            }
          })
          .catch(err => {
            console.log(err)
          }).finally(() => {
            // this.$emit('close', false)
          })
        } else {
          return false
        }
      }
    },
    openPopup () {
        this.customComponent = DocUploadPop
        this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim')
    },
    //파일첨부화면과 팝업을 나누기 위해서 설정
    closePopup () {
      console.log('---  closePopup')
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
    },
    arrangeUploadParam () {
      const attachSelector = document.querySelector('#file_att')
      this.$ekmtcCommon.hideErrorTooltip(attachSelector)
      this.uploadParam.map((item) => (item.newFlag = item.new))
    }
  }
}
</script>

<style scoped>
</style>
